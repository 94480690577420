import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { ReactComponent as SearchIcon } from 'images/search-icon.svg';
import styles from './searchForm.module.scss';

export default function SearchForm({ initialQuery = '' }) {
  const [query, setQuery] = useState(initialQuery);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      navigate(`/search/?needle=${query}`);
    }
  };

  return (
    <div className={styles.container}>
      <form role='search' onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.title}>What can we help you with?</h2>
        <div className={styles.search}>
          <div className={styles.searchForm}>
            <SearchIcon />
            <div className={styles.searchFormInner}>
              <input
                type='text'
                value={query}
                onChange={handleChange}
                onKeyDown={handleSubmit}
                className={styles.searchInput}
                placeholder='Search for articles...'
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
