import React, { useEffect } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import SearchForm from 'components/searchForm';
import Breadcrumbs from 'components/breadcrumbs';
import CategoriesList from 'components/categoriesList';
import { useNavigationLinksContext } from 'contexts/navigationLinksContext';

export default function Category({ data }) {
  const categoryData = data.contentfulCategory;
  const resetNavigationLinks = get(
    useNavigationLinksContext(),
    'resetNavigationLinks',
    () => {}
  );
  const addNavigationLink = get(
    useNavigationLinksContext(),
    'addNavigationLink',
    () => {}
  );
  const findNavigationLink = get(
    useNavigationLinksContext(),
    'findNavigationLink',
    () => {}
  );

  useEffect(() => {
    /*
      1. if current category doesn't have subcategory
      2. if subcategory is present, but current category
      is not sub-category of a previous category added
    */
    if (
      !categoryData?.category ||
      (categoryData?.category &&
        categoryData?.category[0] &&
        !findNavigationLink({
          type: 'category',
          name: categoryData.category[0].title,
          slug: categoryData.category[0].slug,
        }))
    ) {
      resetNavigationLinks();
    }

    addNavigationLink({
      name: categoryData.title,
      slug: categoryData.slug,
      type: 'category',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData.title]);

  return (
    <>
      <Breadcrumbs />
      <SearchForm />
      <CategoriesList
        categoryData={categoryData}
        addNavigationLink={addNavigationLink}
      />
    </>
  );
}

export const categoryQuery = graphql`
  query CategoryQuery($id: String!) {
    contentfulCategory(id: { eq: $id }) {
      ...CategoryFragment
    }
  }

  fragment CategoryFragment on ContentfulCategory {
    id
    title
    slug
    description
    subcategories {
      slug
      title
      members {
        ... on Node {
          __typename
          id
          ... on ContentfulArticle {
            id
            title
            slug
          }
        }
      }
    }
    thumbnail {
      file {
        details {
          image {
            height
            width
          }
        }
        url
      }
    }
    category {
      id
      title
      slug
    }
    members {
      ... on Node {
        __typename
        id
        ... on ContentfulExternalLink {
          __typename
          id
          title
          url
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
        ... on ContentfulArticle {
          id
          title
          slug
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;
