import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgb(230, 230, 230)',
    borderRadius: 8,
    marginTop: 30,
  },
  containerRoot: {
    marginBottom: 40,
  },
  title: {
    fontWeight: 700,
  },
  link: {
    '&:hover': {
      backgroundColor: 'rgba(44, 123, 226, 0.1)',
    },
  },
}));

function ListItemLink(props) {
  const classes = useStyles();
  return <ListItem button component='a' {...props} className={classes.link} />;
}

export default function CategoriesList({ categoryData, addNavigationLink }) {
  const classes = useStyles();

  const handleRedirection = (subCategory) => {
    addNavigationLink({
      name: subCategory.title,
      slug: subCategory.slug,
      type: 'category',
    });
  };

  return (
    <Container maxWidth='lg' className={classes.containerRoot}>
      {categoryData?.subcategories?.map((subCategory) => (
        <div className={classes.listRoot} key={subCategory.title}>
          <List component='nav' aria-label='main mailbox folders'>
            <ListItemLink href={`/category/${subCategory.slug}/`}>
              <Typography variant='h5' className={classes.title}>
                {subCategory.title}
              </Typography>
            </ListItemLink>
          </List>
          <Divider />
          <List component='nav' aria-label='secondary mailbox folders'>
            {subCategory?.members?.map((article) => (
              <ListItemLink
                component='a'
                onClick={() => handleRedirection(subCategory)}
                href={`/article/${article.slug}/`}
                key={article.title}
              >
                <ListItemText primary={article.title} />
              </ListItemLink>
            ))}
          </List>
        </div>
      ))}

      {categoryData?.members?.length > 0 && (
        <div className={classes.listRoot}>
          <List component='nav' aria-label='secondary mailbox folders'>
            {categoryData?.members?.map((member) => (
              <>
                {member.__typename === 'ContentfulExternalLink' ? (
                  <ListItemLink
                    href={member.url}
                    key={member.title}
                    target='_blank'
                  >
                    <ListItemText primary={member.title} />
                  </ListItemLink>
                ) : (
                  <ListItemLink
                    href={`/article/${member.slug}/`}
                    key={member.title}
                  >
                    <ListItemText primary={member.title} />
                  </ListItemLink>
                )}
              </>
            ))}
          </List>
        </div>
      )}
    </Container>
  );
}
